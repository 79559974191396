.user_id_div {
    width: 80%;
    height: 40px;
    border-radius: 1px;
    box-sizing: border-box;
    border: 1px solid #000000;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.user_id_input {
    width: calc(100% - 100px);
    height: 35px;
    margin-right: 10px;
    margin-left: 10px;
    font-family: 'sans-serif';
    font-style: normal;
    font-size: medium;
    color: #000000;
    line-height: 100%;
    box-sizing: content-box;
    vertical-align: middle;
    background: lightgray;
    border: none !important;
}

.user_id_input::placeholder {
    color: #b2b2b2
}

.user_id_title {
    color: #000000;
    width: 100px;
    height: 40px;
    margin-left: 10px;
}