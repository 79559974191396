.home_container {
    width: 90%;
    height: 800px;
    background: lightgray;
    margin-left: 80px;
    margin-right: 30px;
    position: relative;
}

.home_title_div {
    width: 100%;
    height: 40px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 50px;
    vertical-align: middle;
}

.home_title {
    font-family: 'Arial, Helvetica, sans-serif';
    font-style: bold;
    font-size: 40px;
    color: red;
    vertical-align: middle;
    margin-top: 100px;
}

.button_div {
    width: 100px;
    height: 800px;
    position: absolute;
    top: 90%;
    left: 50%;
    margin: -100px 0 0 -100px;
}

.sessionInfo_div {
    margin-left: 10px;
    align-items: left;
}

.home_table {
    width: 80%;
    height: 100%;
    border-radius: 1px;
    box-sizing: border-box;
    border: 1px solid #000000;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.home_tr {
    height: 50px;
    background: lightsteelblue;
}

.home_th {
    height: 50px;
    background: darkgrey;
    position: sticky;
}