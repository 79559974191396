.dialog {
    width: 100px;
    height: 100px;
    background: #FFFFFF;
    border-radius: 4px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
}

.dialog::backdrop {
    background-color: transparent;
}

.progress_content {
    width: 100px;
    height: 100px;
}

.progress_content svg {
    animation: rotate 0.5s linear infinite;
}

@keyframes rotate {
    0% {
      transform: rotateZ(0deg);
    }
  
    100% {
      transform: rotateZ(360deg);
    }
}