.login_error {
    width: 50%;
    height: 40px;
    font-family: 'sans-serif';
    font-style: normal;
    font-size: medium;
    font-weight: 400;
    color: red;
    margin-left: 80px;
    margin-right: auto;
}
