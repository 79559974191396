.menu_button {
    width: 180px;
    height: 40px;
    border-radius: 1px;
    border: 1px solid #000000;
    box-sizing: border-box;
    margin-top: 10px;
    margin-left: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menu_button_title {
    font-family: 'Arial, Helvetica, sans-serif';
    font-style: normal;
    font-size: large;
    color: #000000;
    vertical-align: middle;
}